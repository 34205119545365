import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Yoga.css';
import TrackVisibility from 'react-on-screen';



const Yoga = () => (
  <div className="app__bg app__yoga app__wrapper section__padding" id="yoga">
    <div className="app__wrapper_info yoga">
      <TrackVisibility partialVisibility once>
        {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__fadeInRight" : ""}>
            <SubHeading title="Where the mind, body, and spirit unite" />
            <h1 className="headtext__cormorant">Mori Yoga</h1>
            <p className='p__opensans'>Mori began his activity and learning in yoga in 2009 and soon reached the trainer level due to his enthusiasm and tireless practice.
He is now a Master, but still considers himself a trainee.
He uses his previous experiences and learnings, such as traditional yoga, martial arts and endurance exercises, to share them with his trainees, with the aim of showing them the path to further familiarity and closeness with Mother Nature.
Mori Yoga is a combination of traditional hatha yoga, martial arts and animal movement that increases the motion range and strengthens body awareness.</p>
   <br />
            <button href="#booking" className='custom__button yoga_button flex__center'><a href="#booking">Book a Session</a></button>
          </div>}
      </TrackVisibility>

    </div>

    <div className="app__yoga_img">
      <TrackVisibility partialVisibility once>
        {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__fadeInLeft" : ""}>
            <img src={images.yoga} alt="laurels_img" />
          </div>}
      </TrackVisibility>

    </div>
  </div>
);

export default Yoga;