import React, { useState, useContext } from "react";
import TrackVisibility from "react-on-screen";
import { Row, Col, Button, Navbar, Modal } from "react-bootstrap";
import { productsArray } from "./productsStore";
import ProductCard from "./ProductCard";
import { CartContext } from "./CartContext";
import CartProduct from "./CartProduct";
import "./Store.css";

const Book = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const cart = useContext(CartContext);



  const productCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  return ( 
    <>
      <div
        className="app__store app__bg section__padding flex__center"
        id="booking"
      >
        <Navbar expand="sm" className="app__bg" variant="dark">
          <Navbar.Brand href="/book" className="headtext__cormorant">
           Book a Session{" "}
            <p className="p__opensans"></p>
          </Navbar.Brand>
          <Button
            className="custom__button"
            variant="success"
            onClick={handleShow}
          >
            Cart ({productCount} Items)
          </Button>
        </Navbar>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Your Booking</Modal.Title>

          </Modal.Header>
          <Modal.Body>
            {productCount > 0 ? (
              <>
                <p>Items in your cart:</p>
                {cart.items.map((currentProduct, idx) => (
                  <CartProduct
                    key={idx}
                    id={currentProduct.id}
                    quantity={currentProduct.quantity}
                  >
                    {" "}
                  </CartProduct>
                ))}
                <h1>Total: {cart.getTotalCost().toFixed(2)}</h1>
                <button className="custom__button" onClick={()=> window.open("https://form.jotform.com/231252553810347")}>
                  Complete Order!
                </button>
              </>
            ) : (
              <h1>There are no itmes in your cart!</h1>
            )}
          </Modal.Body>
        </Modal>
      </div>
      <div className="app__bg app__wrapper section__padding">
        <Row xs={1} md={2} lg={4} className="g-4">
          {productsArray.map((product, idx) => (
            <Col align="center" key={idx}>
              <TrackVisibility partialVisibility>
                {({ isVisible }) => (
                  <div
                    className={
                      isVisible ? "animate__animated animate__flipInY" : ""
                    }
                  >
                    <ProductCard product={product} />
                  </div>
                )}
              </TrackVisibility>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Book;
