import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Music2.css';
import TrackVisibility from "react-on-screen"

const Music2 = () => (
  <div className='app__bg app__music app__wrapper flex section__padding' id="music">
      <div className='app__wrapper_music1'>
    <TrackVisibility partialVisibility once>
        {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__fadeInBottomRight" : ""}>
                 <SubHeading title="Music is a universal language." />
      <h1 className='headtext__cormorant'>DJ Mori: Where Beats and Souls Connect</h1>
      <div className='app__chef-content'>
        <p className='p__opensans'>Blending global rhythms with modern beats, he creates moments where the dancefloor becomes a space for connection, joy, and pure energy. Each performance is a unique experience, uniting cultures and emotions through the universal language of music. Let go, feel the pulse, and dance with Mori.</p>
      </div>
      
      <div className='app__chef-sign'>
        <p>Mori’s DJ sets are more than just music—they’re an immersive journey</p>
        <button className='custom__button' variant='warning' onClick={() => window.open("https://lift.bio/morielement", "_blank")} >Contact Mori</button>
      </div>
      
          </div>}
      </TrackVisibility>
    </div>
    <div className='app__music_dotar large-device-margin'>
      <TrackVisibility partialVisibility once>
        {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__fadeInTopLeft" : ""}>
            <img src={images.dj} alt="dotar" className='dotar' />
          </div>}
      </TrackVisibility>
    </div>
  
  </div>
);

export default Music2;
