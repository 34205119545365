import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Music.css';
import TrackVisibility from "react-on-screen"

const Music = () => (
  <div className='app__bg app__music app__wrapper flex section__padding' id="music">
    <div className='app__music_dotar'>
      <TrackVisibility partialVisibility once>
        {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__fadeInTopLeft" : ""}>
            <img src={images.dotar} alt="dotar" className='dotar' />
          </div>}
      </TrackVisibility>
    </div>
    <div className='app__wrapper_music1'>
    <TrackVisibility partialVisibility once>
        {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__fadeInBottomRight" : ""}>
                 <SubHeading title="Music is a universal language." />
      <h1 className='headtext__cormorant'>Where words fail, music speaks.</h1>
      <div className='app__chef-content'>
        <p className='p__opensans'>Since early childhood, Mori became familiar with instruments such as Daf, Setar, Dotar, Jew's harp, flute, hang drum, and sought out native and local instruments to immerse himself in the music and sound of them. He realised that music is the one common language of all human beings, encompassing the range and depth of emotion.</p>
        <p className='p__opensans'>Mori uses music therapy to bring healing, awareness, peace, and connection to Mother Nature, awakening suppressed emotions and reopening hope.</p>
      </div>
      
      <div className='app__chef-sign'>
        <p>Mori sees music as a journey, travelling to the extremes of heaven and earth, and he believes it to be the key to human liberation.</p>
      </div>
          </div>}
      </TrackVisibility>
    </div>
  </div>
);

export default Music;
