import bg from '../assets/bg.webp';
import yoga from '../assets/yoga.webp';
import om from '../assets/om.webp';
import setar from '../assets/setar1.webp';
import logo from '../assets/logo.webp';
import energy from '../assets/energy.webp';
import moribanner from '../assets/moribanner.webp';
import energy1 from '../assets/energy1.webp';
import dotar from '../assets/dotar.webp';
import handpan from "../assets/handpan.webp"
import energy2 from "../assets/energy2.webp"
import massage from "../assets/massage.webp"
import fire from "../assets/fire.webp"
import festival from "../assets/festival.webp"
import bglogo from "../assets/bglogo.webp"
import icon from "../assets/icon.webp"
import icon1 from "../assets/icon1.webp"
import dj from "../assets/dj.webp"



const images ={
  dj,
  bglogo,
  massage,
  energy2,
  handpan,
  energy1,
  bg,
  dotar,
  logo,
  energy,
  moribanner,
  setar,
  yoga,
  om,
  fire,
  festival,
  icon,
  icon1
};

export default images

