import React from "react";
import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Massage.css";
import TrackVisibility from "react-on-screen";

const Massage = () => (
  <div
    className="app__wrapper app__massage app__bglogo section__padding"
    id="massage"
  >
    <div className="app__massage_img">
      <TrackVisibility partialVisibility once>
        {({ isVisible }) => (
          <div
            className={
              isVisible ? "animate__animated animate__fadeInTopRight" : ""
            }
          >
            <img src={images.massage} alt="find us" />
          </div>
        )}
      </TrackVisibility>
    </div>
    <div className="app__wrapper_info">
      <TrackVisibility partialVisibility once>
        {({ isVisible }) => (
          <div
            className={
              isVisible ? "animate__animated animate__fadeInTopLeft" : ""
            }
          >
            <SubHeading title="the Japanese discipline for postural correction" />
            <h1
              className="headtext__cormorant"
              style={{ marginBottom: "3rem" }}
            >
              Yumeiho Massage Therapy
            </h1>
            <div className="app__wrapper-content">
              <p className="p__opensans">
              Yu-Mei-Ho is a therapeutic method based on manual actions performed on the entire human body for maintaining or improving health; it includes about 100 maneuvers performed on the entire patient's body.
The Yu-Mei-Ho therapy consists of the methodical application of a complex set of manual procedures and physical exercises meant to restore the functional balance of the human organism.
Although Yu-Mei-Ho is a method designed to be mainly preventively applied, it is still called "therapy" due to the fact its main purpose is treating or reducing the causes of functional disorders which, in time, may result in chronic sufferings.
Also, the Yu-Mei-Ho therapy can be applied in order to prevent or to slow down the progress of already existing sufferings.
Mori has been practicing in this field for the past 12 years, and has faith in this revolutionary method for treating people today, as it would free them from lengthy, tiring and costly treatments or surgery.
             {/* Yu-Mei-Ho is a therapeutic method based on manual actions
                performed on the entire human body for maintaining or improving
                health; it includes about 100 manoeuvres performed on the entire
                patient’s body. It is also known as the “method of correcting
                hip bones, massage and acupressure” or “Saionji Oriental Special
                Massage”, but the original name is “Yumeiho Therapy”. The
                Yu-Mei-Ho therapy consists of the methodical application of a
                complex set of manual procedures and physical exercises meant to
                restore the functional balance of the human organism. Thus, a
                diverse range of disorders that may cause both acute and chronic
                diseases is eliminated or ameliorated. Although Yu-Mei-Ho is a
                method designed to be mainly preventively applied, it is still
                called “therapy” due to the fact its main purpose is treating or
                reducing the causes of functional disorders which, in time, may
                result in chronic sufferings. Also, the Yu-Mei-Ho therapy can be
                applied in order to prevent or to slow down the progress of
                already existing sufferings. Mori has been practicing in this
                field for the past 12 years, and has faith in this revolutionary
                method for treating people today, as it would free them from
                lengthy, tiring and costly treatments or surgery. At the same
                time with commencing yoga training, Mori became familiar with
                Yumeiho Massage therapy. He received trainings in this field in
                India, Nepal and the like, and applies this method of healing
                and correcting the posture in the spine, pelvis, cervical
                vertebrae and joints, and the like. This method is a combination
                of chiropractic, osteopathy, Shiatsu, and reflection massage,
                which is used for restoring physical balance and balancing the
                individual’s body through therapy*/}
              </p>
            </div>
            <br />
            <button href="#booking" className="custom__button">
              <a href="#booking">Book a Session</a>
            </button>
          </div>
        )}
      </TrackVisibility>
    </div>
  </div>
);

export default Massage;
