import React from 'react';
import { images } from '../../constants';
import { SubHeading} from '../../components';
import './Energy.css';
import TrackVisibility from "react-on-screen"

const Energy = () => (
  <div className='app__energy app__bglogo flex__center section__padding' id="energy">
    <TrackVisibility partialVisibility once>
      {({ isVisible }) =>
        <div className={isVisible ? "animate__animated animate__fadeInDownBig" : ""}>
          <div className='app__energy-title1'>
            <SubHeading title="Balance Your Energy, Balance Your Life" />
            <h1 className='headtext__cormorant'>Energy Therapy</h1>
          </div>
        </div>}
    </TrackVisibility>


    <div className='app__energy-menu flex'>
      {/* <div className='app__specialMenu_img'>
        <img src={images.energy} alt="energy" />
      </div> */}
        <TrackVisibility partialVisibility once>
      {({ isVisible }) =>
        <div className={isVisible ? "animate__animated animate__fadeInRightBig" : ""}>
          
          <div className='app__energy_img'>
        <img src={images.energy2} alt="energy" />
      </div>
         
        </div>}
    </TrackVisibility>
      <div className='app__energy_context'>
        <TrackVisibility partialVisibility once>
          {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__fadeInUpBig" : ""}>
              <div className='app__energy-title flex__center'>
                <p className='p__opensans'>
                Mori applies energy therapy as a fundamental and vital element for professional skills, beside yoga and massage, in his therapy courses and classes.
He started energy therapy with Reiki classes, and after years and living with one of his great masters, he obtained a vast knowledge in the field.
Mori considers us to be a part of the universal energy, and that our confidence and love for this vital energy will lead us to true peace and liberation.
                  {/*Mori applies energy therapy as a fundamental and vital element for professional skills, beside yoga and massage, in his therapy courses and classes.
                  He started energy therapy with Reiki classes, and after years and living with one of his great masters, he obtained a vast knowledge in the field.
                  He views energy therapy in a new light, and believes that any movement produces an energy, with the only real energy being the one generated with love and honesty. In his therapy programs, he opens a window that connects people with the universal energy.
                  There are different styles and approaches in this respect, of course, but energy therapy for him is a combination of his achievements, experiences, instructions and works.
                  Mori considers us to be a part of the universal energy, and that our confidence and love for this vital energy will lead us to true peace and liberation.*/}</p>
              </div>
              <button href="#booking" className='custom__button energy_button'><a href="#booking">Book a Session</a></button>
              
            </div>}
        </TrackVisibility>

      </div>
      
      <TrackVisibility partialVisibility once>
      {({ isVisible }) =>
        <div className={isVisible ? "animate__animated animate__fadeInLeftBig" : ""}>
          
          <div className='app__energy_img'>
        <img src={images.energy} alt="energy" />
      </div>
          
        </div>}
    </TrackVisibility>
      

    </div>
  </div>
);

export default Energy;
