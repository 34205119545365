import React, { useState } from 'react';
import { images } from '../../constants';
import "./Popup.css"

const FullscreenPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const closePopup = () => {
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <div
      className='popup'
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <div
        className='headtext__cormorant'
        style={{
          position: 'relative',
          width: '90%',
          height: '90%',
          maxWidth: '1000px',
          maxHeight: '1000px',
          background: `url(${images.bg}) center/cover`,
          borderRadius: '4px',
          overflow: 'hidden',
        }}
      >
        <h1
          className='headtext__cormorant'
          style={{
            textAlign: 'center',
            fontSize: '2rem', // Responsive font size based on viewport width
            marginTop: '30px', // Adjust the margin as needed
            marginBottom: '10px', // Adjust the margin as needed;
            background:"transparent"
          }}
        >
          Welcome to Mori Element
        </h1>
    
        <img
          className='icon1'
          src={images.icon1} // Replace with your logo image source
          alt="Logo"
          style={{
            display: 'block',
            margin: '0 auto', // Centers the logo horizontally
            marginTop: '70px', // Adjust the margin as needed
            paddingBottom: '10px',
            width: '40%', // Set the image width to 100% of its container
            height: 'auto', // Allows the image to scale proportionally
          }}
        />
      
        <a
          className='popup'
          onClick={closePopup}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: 'absolute',
            top: '70%',
            padding: '5%',
            left: 0,
            width: '50%',
            height: '50%',
            transition: 'color 0.3s',
            cursor: 'pointer',
            textAlign: 'center',
          }}
        >
          Private Session
        </a>
        <a
          className='popup'
          onClick={closePopup}
          href="https://www.backtobalance-festival.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: 'absolute',
            top: '70%',
            right: 0,
            width: '50%',
            height: '50%',
            padding: '5%',
            textAlign: 'center',
            transition: 'color 0.3s',
            
          }}
        >
          Event Management
        </a>
      </div>
    </div>
  );
}  
export default FullscreenPopup;
