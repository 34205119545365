import React from 'react';
import { AboutUs, Music, Massage, Footer, Header, Intro, Fire, Yoga, Energy, Store, Event } from './container';
import { Navbar } from './components';
import './App.css';
import { Container } from 'react-bootstrap';
import CartProvider from './container/Store/CartContext';
import Popup from './components/Popup/Popup';
import Music2 from './container/Dj/Music2';



const App = () => (
  <>
    <Popup />
    <CartProvider>
      <Navbar />
      <Header />
      <AboutUs />
      <Massage />
      <Yoga />
      <Energy />
      <Event />
      <Music2 />
      <Music />
      <Fire />
      <Intro />
      <Container>
        {/* <BrowserRouter>
          <Routes>
            <Route path="success" element={<Success />} />
            <Route path="cancel" element={<Cancel />} />
          </Routes>
        </BrowserRouter> */}
      </Container>
      <Store></Store>
    </CartProvider>
    <Footer />
  </>
);

export default App;
