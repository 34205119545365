import React from 'react';

import { meal } from '../../constants'
import './Intro.css';
import ReactPlayer from 'react-player'
import TrackVisibility from "react-on-screen"

const Intro = () => {
  return (
    <div className=" section__padding" id="video">
    <div className='app__video' >
      <TrackVisibility partialVisibility once>
        {({ isVisible }) => isVisible &&
          <div className={isVisible ? "player-wrapper animate__animated animate__fadeInUpBig" : ""} >
            <ReactPlayer className='react-player' width='100%'
              height='100%' url={meal} controls playing={false} loop={false} />
          </div>
        }
      </TrackVisibility>
      </div>
   
      </div>

  )
};

export default Intro;
