import React from 'react'
import TrackVisibility from 'react-on-screen';
import { images } from '../../constants';
import { SubHeading } from '../../components';
import "./Fire.css"

const Fire = () => {
  return (
    <div className='app__fire app__bg app__wrapper section__padding' id="fire">
           <div className='app__wrapper_info'>
        <TrackVisibility partialVisibility once>
          {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__fadeInRight" : ""}>
              <SubHeading title="Fire is the king of all elements, for it is powerful enough to transform them all" />
              <h1 className='headtext__cormorant'>"Set your life on fire. Seek those who fan your flames."</h1>
              
            </div>}
        </TrackVisibility>
      </div>
      <div className='app__wrapper_img3'>
        <TrackVisibility partialVisibility once>
          {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__fadeInLeft" : ""}>
              <img src={images.fire} alt="fire" />
              <div className='app__chef-content'>
                <p className='p__opensans'>Mori holds a fire in his hands, and he hopes to spread it with the utmost passion all over the world.
                  His world is thus filled with love, friendship, light, color and life.</p>
                <p className='p__opensans'>This is a fairytale-like story about the alluring and intense nature of fire. It's an impressive and stunning display of virtuosic flames and aerial acrobatics that captures the audience's attention with its dangerously beautiful spectacle. The performers gracefully dance amidst gusts of sparkles and streams of fire, creating an awe-inspiring and memorable experience that leaves the audience spellbound.</p>
              </div>
              <button className='custom__button' variant='warning' onClick={() => window.open("https://lift.bio/morielement", "_blank")} >Contact Mori</button>
            </div>}
        </TrackVisibility>
      </div>
 
    </div>
  );
}

export default Fire