import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
// const demos = {
//     facebook:
//       '<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fevents%2F618934109829068%2F618939193161893%3Fevent_time_id%3D618939183161894&tabs=events&width=500&height=100&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="500" height="100" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>',

//   };
//   function Iframe(props) {
//     return (
//       <div
//         dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
//       />
//     );
//   }
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
        {/* <Iframe iframe={demos["facebook"]} allow="autoplay" />, */}
    </React.StrictMode>
)