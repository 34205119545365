import React from "react";
import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Event.css";
import TrackVisibility from "react-on-screen";

const Event = () => (
  <div
    className="app__wrapper section__padding app__bglogo app__event"
    id="event"
  >
    <div className="app__findus_img">
      <TrackVisibility partialVisibility once>
        {({ isVisible }) => (
          <div
            className={
              isVisible ? "animate__animated animate__fadeInBottomRight" : ""
            }
          >
            <img src={images.festival} alt="find us" />
          </div>
        )}
      </TrackVisibility>
    </div>
    <div className="app__wrapper_info">
      <TrackVisibility partialVisibility once>
        {({ isVisible }) => (
          <div
            className={
              isVisible ? "animate__animated animate__fadeInBottomLeft" : ""
            }
          >
            <SubHeading title="YOGA I WORKSHOPS I ART I CULTURE MUSIC I DANCE I MARKET I SOULFOOD I PERFORANCE" />
            <h1
              className="headtext__cormorant"
              style={{ marginBottom: "3rem" }}
            >
              Festival & Event Management
            </h1>
            <div className="app__wrapper-content">
              <p className="p__opensans">
                Mori applies energy therapy as a fundamental and vital element
                for professional skills, beside yoga and massage, in his therapy
                courses and classes. He started energy therapy with Reiki
                classes, and after years and living with one of his great
                masters, he obtained a vast knowledge in the field. Mori
                considers us to be a part of the universal energy, and that our
                confidence and love for this vital energy will lead us to true
                peace and liberation.
                {/*Inspired by the experience of attending numerous festivals in different countries, assisting in holding various ones, and meeting countless people in the field, Mori decided to hold his own festival in 2020.

                He thus channeled his staggering experience into founding the “Back to Balance” Festival.
                The Festival includes attractive programs for all age groups, from children to adults (and any individuals that seek the light), and can be performed in 1 to 5 days.
                Programs such as workshops, music, dance, sports, therapy, massage, small markets, and the like are conducted in the Festival.
                Mori’s decision to hold his own festival was formed when he realised he could create a festival to gather people together, so that their hearts could become intimate in an environment where they could find their true selves and get in touch with their feelings, to generate real love. */}
              </p>
            </div>
            <br />
            <button className="custom__button">
              <a  onClick={()=> window.open("http://www.backtobalance-festival.com/","_blank")} >
                Back To Balance
              </a>
            </button>
          </div>
        )}
      </TrackVisibility>
    </div>
  </div>
);

export default Event;
