import React from 'react';
import { images } from '../../constants';
import './AboutUs.css';
import TrackVisibility from "react-on-screen"

const AboutUs = () => (
  <div className='app__aboutus app__bg flex__center section__padding' id="about">
    <div className='app__aboutus-overlay flex__center'>
      <TrackVisibility partialVisibility once>
        {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__rotateIn" : ""}>
            <img src={images.om} alt="om" />
          </div>}
      </TrackVisibility>

    </div>
    <div className='app__aboutus-content flex__center'>
      <div className='app__aboutus-content_about'>
        <TrackVisibility partialVisibility once>
          {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__slideInLeft" : ""}>
              <h1 className='headtext__cormorant'>About Me</h1>
              
              <p className='p__opensans'>Mori is an Iranian man on the quest for discovery, intuition, and learning, which is evident in his worldview and lifestyle. He started working at the age of 14, and he obtained a varied work experience, everything from manual labor to positions in large, prestigious companies, by the time he was 20. It was at that time that he met a master who completely transformed his worldview and who conveyed a completely new perspective of life to Mori. He was introduced to yoga training programs and regularly attended different yoga, massage, and energy therapy courses and workshops, engaging in them more seriously and deeply as he progressed. </p>
            </div>}
        </TrackVisibility>

      </div>
      <div className='app__aboutus-content_setar flex__center'>
        <img src={images.setar} alt="about_setar" />
      </div>
      <div className='app__aboutus-content_history'>
        <TrackVisibility partialVisibility once>
          {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__slideInRight" : ""}>
              <h1 className='headtext__cormorant'>My History</h1>
              <p className='p__opensans'>In countries such as India, Nepal, and the like, he received lengthy, meaningful, contemplative experiences, not in ordinary conditions, but in the heart of nature, away from everyday, urban life, in utterly primitive conditions and often facing extreme hardships. These experiences transformed his perspective toward the world, people, culture, and traditions. Consequently, Mori became acquainted with a new world, he grew beyond himself, and his life became a meditation, a gift, a cycle of challenges and then a return to balance.
                Since 2009, Mori has been a yoga trainer, masseur / bodywork practitioner, and energy therapist. Music has also become an inseparable part of his life, with music therapy playing an integral role in his work and interests. Mori has dedicated his life to healing, instructing, and sharing his experiences and knowledge with others, and he strives to spread self-awareness, self-knowledge, and self-therapy to everyone he meets across the globe; this is his path and objective.</p>
            </div>}
        </TrackVisibility>
      </div>
    </div>
  </div>
);

export default AboutUs;
